import React from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import Seo from "../../components/seo"
import LeftPanel from "../../components/panel/leftpanel"
import Search from "../../components/search/knowledgeSearch"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Highlighter from "react-highlight-words"
import JwPagination from "../../components/pagination/pagination"
import Moment from "react-moment"
import "./knowledgebase.css"

const Banner = ({ posts }) => (
  <>
    <div className="hero-banner" style={{ backgroundColor: "#34424c" }}>
      <div className="container">
        <div className="hero-banner-content text-center">
          <h1 className="page-title font-weight-bold text-white mt-2">
            Knowledge Base
          </h1>
          <Search posts={posts} />
        </div>
      </div>
    </div>

    <div className="breadcrumbs">
      <div className="container">
        <ul className="list-unstyled">
          <li>
            <Link aria-label="link" to="/knowledge-base">
              Knowledge Base
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </>
)

class SearchPage extends React.Component {
  constructor(props) {
    super(props)

    const { allSanityKnowledgeBase } = this.props.data
    const allPosts = allSanityKnowledgeBase.edges
    const queryString = this.props.location.search.replace("?=", "")

    const searchParam = allPosts.filter((item, idx) => {
      const queryParam = item.node.body
        ? item.node.body.toLowerCase().includes(queryString)
        : item.node.discription.toLowerCase().includes(queryString)
      return item.node.title.toLowerCase().includes(queryString) || queryParam
    })

    let latex = searchParam.map(({ node }) => node)
    this.onChangePage = this.onChangePage.bind(this)
    this.state = {
      pageOfItems: [],
      searchParam,
      latex,
      queryString,
      allPosts,
    }
  }
  onChangePage(pageOfItems) {
    this.setState({ pageOfItems })
  }

  render() {
    const { queryString, searchParam, allPosts, pageOfItems } = this.state
    const { data } = this.props
    const posts = data.categoryBase.edges

    return (
      <Layout
        banner={<Banner posts={allPosts} />}
        location={this.props.location}
      >
        <Seo
          title={`You searched for ${queryString} - Quandary Consulting Group`}
          description="Expand your bottom line. Exceed customer expectations. Experience efficiency like never before.Choose Quandary to guide your workflow optimization."
        />
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="intro mb-5">
                  {queryString !== "" ? (
                    <h3 className="font-weight-medium">
                      Found {searchParam.length} results for: {queryString}
                    </h3>
                  ) : (
                    <h3 className="font-weight-medium">
                      There where no results for your search.
                    </h3>
                  )}
                </div>

                <div className="knowledge-section-list mb-4">
                  {queryString !== "" ? (
                    <>
                      {pageOfItems.map((item, idx) => {
                        return (
                          <LazyLoadComponent>
                            <div key={item.id} className="knowledge-post-link">
                              <div className="knowledge-subheading d-flex jsutify-content-start">
                                <span
                                  aria-hidden="true"
                                  className="knowledge-heading-icon fa fa-file-text-o colored mt-1 mr-2"
                                />
                                {item.categories.map((cat, idx) => {
                                  return (
                                    <>
                                      <h5 className="font-weight-bold m-0">
                                        <Link
                                          to={`/knowledge-base/${cat.slug.current}/${item.slug.current}/`}
                                          data-wpel-link="internal"
                                        >
                                          {item.title}
                                        </Link>
                                      </h5>
                                    </>
                                  )
                                })}
                              </div>
                              <div className="knowledge-body p-3">
                                <Highlighter
                                  searchWords={[queryString]}
                                  autoEscape={true}
                                  highlightClassName="text-light-blue font-weight-medium"
                                  textToHighlight={item.body
                                    .slice(0, 200)
                                    .replace(/#/g, "")}
                                />
                              </div>
                              <div className="knowledge-created">
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    {item.categories.map((cat, idx) => {
                                      return (
                                        <>
                                          <p className="text-small m-0 result-category">
                                            <Link
                                              to={`/knowledge-base/${cat.slug.current}/`}
                                              data-wpel-link="internal"
                                            >
                                              {cat.title}
                                            </Link>
                                          </p>
                                        </>
                                      )
                                    })}
                                  </div>
                                  <div className="col-md-6 text-md-right">
                                    <p className="text-small m-0">
                                      <span
                                        className="fa fa-clock-o"
                                        aria-hidden="true"
                                      ></span>{" "}
                                      <Moment
                                        add={{ days: 1, hours: 9 }}
                                        format="MMMM DD, YYYY"
                                      >
                                        {item.publishedAt}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </LazyLoadComponent>
                        )
                      })}
                    </>
                  ) : (
                    <div className="search-result-loading" />
                  )}
                </div>
                <JwPagination
                  items={this.state.latex}
                  onChangePage={this.onChangePage}
                />
              </div>
              <div className="col-md-3">
                <LeftPanel posts={posts} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SearchPage

export const searchQuery = graphql`
  query searchQuery {
    categoryBase: allSanityKnowledgebasecategory(
      sort: { fields: [order, knowledgebasepage___publishedAt], order: ASC }
    ) {
      edges {
        node {
          id
          title
          icon
          slug {
            current
          }
          subCategory {
            id
            title
            slug {
              current
            }
            knowledgebasepage {
              id
              title
              slug {
                current
              }
            }
          }
          knowledgebasepage {
            id
            title
            publishedAt
            slug {
              current
            }
          }
        }
      }
    }
    allSanityKnowledgeBase(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          metaDescription
          body
          publishedAt
          _createdAt
          slug {
            current
          }
          categories {
            ... on SanityKnowledgebasecategory {
              id
              title
              slug {
                current
              }
              subCategory {
                title
                slug {
                  current
                }
              }
            }
            ... on SanitySubCategory {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`
